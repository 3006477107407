import React from 'react'
import { navigate, PageProps } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useForm } from '@formcarry/react'
import { SVG, easing } from '@svgdotjs/svg.js'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'
import useElementSize from '../hooks/useElementSize'
import useHeaderLines from '../hooks/useHeaderLines'

const Contact: React.FC<PageProps> = () => {
  const { state, submit } = useForm({
    id: 'DUVTbdAQ6F',
  })
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector('.page--contact'),
      )
      const contactFormRect = getAbsoluteOffset(
        document.querySelector('.form--contact'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      const polyline = svg
        .polyline([
          ...headerLines,

          pageRect.left,
          headerRect.bottom,

          pageRect.left,
          contactFormRect.bottom,

          document.body.clientWidth,
          contactFormRect.bottom,
        ])
        .stroke({
          width: 0,
        })
      const polylineLength = polyline.node.getTotalLength()
      polyline
        .attr({
          'stroke-width': 5,
          'stroke-dashoffset': polylineLength,
          'stroke-dasharray': polylineLength,
        })
        .animate({ duration: 3000, delay: 0 })
        .ease(easing['<>'])
        .attr({
          'stroke-dashoffset': 0,
        })

      return () => svg.remove()
    }
  }, [size])

  if (state.submitted) {
    navigate('/thank-you')
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <main ref={pageRef} className={`container page--contact`}>
        <form className="form form--contact" onSubmit={submit}>
          <label className="label label--fname">
            <div className="label__text">Name</div>
            <input
              className="input"
              placeholder="Name"
              type="text"
              name="fname"
              // ref={register({ required: true })}
            />
            {/* {errors.fname && (
              <span className="message">This field is required</span>
            )} */}
          </label>
          <label className="label label--lname">
            <div className="label__text">Surname</div>
            <input
              className="input"
              placeholder="Surname"
              type="text"
              name="lname"
              // ref={register({ required: true })}
            />
            {/* {errors.lname && (
              <span className="message">This field is required</span>
            )} */}
          </label>
          <label className="label label--company">
            <div className="label__text">Company</div>
            <input
              className="input"
              placeholder="Company"
              type="text"
              name="company"
              // ref={register}
            />
          </label>
          <label className="label label--email">
            <div className="label__text">Email</div>
            <input
              className="input"
              placeholder="Email"
              type="email"
              name="email"
              // ref={register({ required: true })}
            />
            {/* {errors.email && (
              <span className="message">This field is required</span>
            )} */}
          </label>
          <label className="label label--cnumber">
            <div className="label__text">Contact number</div>
            <input
              className="input"
              placeholder="Contact"
              type="text"
              name="cnumber"
              // ref={register}
            />
          </label>
          <label className="label label--location">
            <div className="label__text">Location</div>
            <input
              className="input"
              placeholder="Project location"
              type="text"
              name="location"
              // ref={register}
            />
          </label>
          <label className="label label--message">
            <div className="label__text">How can we help?</div>
            <textarea
              name="message"
              cols={30}
              rows={3}
              placeholder="Your enquiry"
              // ref={register}
            />
          </label>
          <div className="form-actions">
            <button className="btn btn--primary" type="submit">
              Submit
            </button>
          </div>
        </form>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2477.3892297320417!2d-0.1452499!3d51.6160788!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876199e6b1baa11%3A0xe83a01fe9215fb5e!2sBlack%20Elephant%20Architecture%20Design%20Studio!5e0!3m2!1sen!2sin!4v1601629919618!5m2!1sen!2sin"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            aria-hidden="false"
            tabIndex={0}
          ></iframe>
        </div>
      </main>
    </Layout>
  )
}

export default Contact
